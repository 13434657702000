<template>
    <div class="browse_container">
        <!-- 表格数据区域 -->
        <el-table style="width: 100%" border stripe :data="list"
            :header-cell-style="{ background: '#F2F6FC', color: '#606266' }">
            <el-table-column align="center" prop="headLogo" label="头像" width="200">
                <template slot-scope="scope">
                    <div class="permitImg-img" v-if="scope.row.headLogo">
                        <el-image style="width: 35%" :src="'http://file.innopinenut.com/' + scope.row.headLogo"
                            :preview-src-list="[
                                'http://file.innopinenut.com/' + scope.row.headLogo,
                            ]" />
                    </div>
                    <img style="width: 35%" v-else src="../../assets/head.png" alt="">
                </template>
            </el-table-column>
            <el-table-column align="center" prop="browseUserName" label="浏览人" :show-overflow-tooltip="true" />
            <el-table-column align="center" prop="createTime" label="创建时间" width="200">
                <template slot-scope="scope">
                    {{ scope.row.createTime | getLocalTime }}
                </template>
            </el-table-column>
        </el-table>

        <!-- 分页 -->
        <pagination v-show="total > 0" :total="total" :page.sync="info.page" :limit.sync="info.pageSize"
            @pagination="getViewRecords" />
    </div>
</template>

<script>
import { getViewRecords } from '../../request/http'

export default {
    data() {
        return {
            info: {
                originId: '',
                page: 1,
                pageSize: 10
            },
            list: [],
            total: 0
        }
    },
    created() {
        this.info.originId = this.$route.query.id
        this.getViewRecords()
    },
    methods: {
        async getViewRecords() {
            const res = await getViewRecords(this.info)
            console.log(res);
            if (res.code !== 0) {
                return this.$message.error(res.code)
            }
            this.list = res.data
            this.total = res.count
        }
    }
}
</script>

<style scoped lang='scss'>
.browse_container {
    padding: 30px;
}
</style>